<script>
  // import { fly } from 'svelte/transition'
  let endOfYearShow = false
  // chaeck for Sunday 
  let todayDate = new Date();
  let dayCount = todayDate.getDay();
  import Sizzler from '$assets/SizzlerYellow.png'
  
  

  let todaysLetterCounter = 0
const rand = function() {
  return Math.random().toString(36).substr(2); // remove `0.`
};

const token = function() {
    return rand() + rand() + "-" + rand(); // to make it longer
};
let playerid = token()

  let placeholder
    let showAnswer = false
    import confetti from 'canvas-confetti'
    import { onMount } from 'svelte'
    import { dev, browser } from '$app/environment'
    const lsname = 'crossword' // local storage key
    export let link_indicator;
    export let data
    // $: console.log(data);

    // $: console.log("this is what the indicator has decided: ", link_indicator);

    function logSolvedResult() { 
      setTimeout(() => { 
      let g = 0
      let s = true

      if(statistics.state.guess1Result === 'correct') { 
        g = 1
      } 
      if(statistics.state.guess2Result === 'correct') { 
        g = 2
      }
      if(statistics.state.guess3Result === 'correct') { 
        g = 3
      }

      let url = "https://mediaverse.co.za/crossword/log-result.php?start=false&guesses=" + g + "&solved=" + s + "&playerid=" + playerid
      // console.log(url);
      
      fetch(url)
      },1000)
    }


    function logUnsolvedResult() { 
      setTimeout(() => { 
      let g = 3
      let s = false

      let url = "https://mediaverse.co.za/crossword/log-result.php?start=false&guesses=" + g + "&solved=" + s + "&playerid=" + playerid
      console.log(url);
      
      fetch(url)
      },1000)
    }
    

   
    $: clues = data.data
    import { MousePointerClick, X, CheckCircle2, XCircle, Share2 } from 'lucide-svelte';

    function updateStatsTable() { 
  if(browser && localStorage.getItem(lsname)) {
      statistics = JSON.parse(localStorage.getItem(lsname))

       // Update played
       statistics.state.played = statistics.record.length
      // Update solved
      statistics.state.solved = statistics.record.filter(s => s.solved).length

      // Update Streak
      let streakCount = 0
      let streakDone = false
      statistics.record.forEach(s => {
  
        
        if(s.solved && !streakDone) {
          streakCount++
          statistics.state.streak = streakCount
        } else { 
          statistics.state.streak = streakCount
          streakDone = true
        }
     
      })

      localStorage.setItem(lsname, JSON.stringify(statistics))
      
    }
}


    


    function updateStats() {
        if(browser) { 
        localStorage.setItem(lsname, JSON.stringify(statistics))
        updateStatsTable()
        }
    }

  var todaysDate = new Date().toISOString().slice(0, 10)

  let yesterdaysDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10)

 
  let showWrong = false
  let statistics = {
    record: [],
    state: {
      lastPlayedDate: '',
      currentGameDate: todaysDate,
      currentGameCompleted: false,
      attempts: 0,
      showHint1: true,
      showHint2: false,
      showResult: false,
      displayGuess: '',
      played: 0,
      solved: 0,
      streak: 0,
      attempts1: 0,
      attempts2: 0,
      attempts3: 0,
      guess1: null, 
      guess2: null, 
      guess3: null, 
      guess1Result: 'incorrect', 
      guess2Result: 'incorrect', 
      guess3Result: 'incorrect'
    },
    
  }



  // check if local storage
  if(browser) { 
  if (localStorage.getItem(lsname) === null) {
    localStorage.setItem(lsname, JSON.stringify(statistics))
  } else {
    statistics = JSON.parse(localStorage.getItem(lsname))
  }


  

}
 
  import {
    guess,
    guessLength,
    todaysSolutionLength,
    attempts, 
    currentSolution
  } from '../store.js'

  import Keyboard from '$components/Keyboard.svelte'
//   import confetti from 'canvas-confetti'
  let displayGuess = ''
  let showResult = false

  import { fly, fade } from 'svelte/transition'
  let showSolution = false

  let yesterdayClue = ''
  let yesterdaySolution = ''
  let yesterdayExplanation = ''
  let todaysClue = ''
  let todaysSetter = ''
  let todaysSolution = ''
  let todayHint1 = ''
  let todayHint2 = ''
  let solved = false
  let yesterdayHint1 = ''
  let today = ''
  let yesterday = ''

  // let placeholder = 'Your Answer'
  


 
  updateStats()
  updateStatsTable()

  function resetGameDetails() { 
    statistics.state.attempts = 0
    showResult = false
    displayGuess = ''
    statistics.state.currentGameDate = todaysDate
    statistics.state.showHint1 = true
    statistics.state.showHint2 = false
    statistics.state.showResult = false
    statistics.state.displayGuess = ''
    statistics.state.guess1 = null
    statistics.state.guess2 = null
    statistics.state.guess3 = null
    statistics.currentGameCompleted = false
  }
  // check if current game data and current data match
  if (statistics.state.currentGameDate !== todaysDate) {
    resetGameDetails()
    updateStats()
  }

  // if last played !-== today 
  if (statistics.state.lastPlayedDate !== todaysDate) {
    resetGameDetails()
    updateStats()
    updateStatsTable()
  }

  // reset streak if last plaued not today or yesterday
  if (
    statistics.state.lastPlayedDate !== todaysDate &&
    statistics.state.lastPlayedDate !== yesterdaysDate
  ) {
    statistics.state.streak = 0
    updateStats()
  }


  // update stats if number of attempts changes
  $: if (statistics.state.attempts) {
    updateStats()
  }
  // ////////////////////// Pull data from localstorage




  $: if (guessLength > 0 && guessLength !== todaysSolutionLength) {
    showResult = false
  }

  $: $guessLength = $guess.length

  // date formatter
  var dateFormat = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  function getDays() {
    let t = dateFormat.formatToParts(new Date())
    let y = dateFormat.formatToParts(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    )

    today = t[4].value + '-' + t[2].value + '-' + t[0].value
    yesterday = y[4].value + '-' + y[2].value + '-' + y[0].value
  }

  onMount(() => {
    // logStart()
    getDays()
    yesterdayClue = clues.filter((d) => d.date === yesterday)[0].clue
    yesterdaySolution = clues.filter((d) => d.date === yesterday)[0].solution
    yesterdayHint1 = clues.filter((d) => d.date === yesterday)[0].hint1
    yesterdayExplanation = clues.filter((d) => d.date === yesterday)[0]
      .explanation
    todaysClue = clues.filter((d) => d.date === today)[0].clue

// !!!!!!!!  get word lengths first 
todaysLetterCounter = clues.filter((d) => d.date === today)[0].solution.split(" ")
        // console.log('todaysLetterCounter', todaysLetterCounter);

    todaysSolution = clues.filter((d) => d.date === today)[0].solution.replaceAll(",","").replaceAll(" ", "")
    $todaysSolutionLength = todaysSolution.length
    $currentSolution = todaysSolution
    todayHint1 = clues.filter((d) => d.date === today)[0].hint1
    todayHint2 = clues.filter((d) => d.date === today)[0].hint2
    todaysSetter = clues.filter((d) => d.date === today)[0].source_setter
  })

  function updateRecord(attempts, solved, date) { 

    // set record 
        // check for existing record
        let record = statistics.record.filter((d, i) => d.date === todaysDate)
        if(record.length === 0) { 
          statistics.record.push({
            date: date,
            attempts: attempts,
            solved: solved
          })
        }
        else { 
          // get index
          let i = statistics.record.findIndex((d) => d.date === todaysDate)
          let updatedRecord = { 
            date: date,
            attempts: attempts,
            solved: solved
          }
          statistics.record[i] = updatedRecord

        }
        updateStats()

  }

  function checkAnswer() {
    // if(statistics.state.currentGameCompleted ) { 
    //   return
    // }
    if ($guessLength === $todaysSolutionLength) {
      if (
        $guess.toLowerCase() === todaysSolution.toLowerCase() &&
        $attempts < 3
      ) {
        logSolvedResult()
        confetti({particleCount: 150, spread: 180})
        solved = true
        showResult = true
        statistics.state.showResult = true
        statistics.state.played = statistics.state.played + 1
        statistics.state.currentGameCompleted = true
        displayGuess = $guess
        statistics.state.solved = statistics.state.solved + 1
        if (statistics.state.attempts === 0) {
          statistics.state.attempts = 1
            statistics.state.guess1 = $guess
          statistics.state.attempts1 = statistics.state.attempts1 + 1
          statistics.state.guess1Result = 'correct'
        }
        else if (statistics.state.attempts === 1) {
          statistics.state.attempts = 2
            statistics.state.guess2 = $guess
            statistics.state.attempts2 = statistics.state.attempts2 + 1
            statistics.state.guess2Result = 'correct'
        }
        else if (statistics.state.attempts === 2) {
          statistics.state.attempts = 3
            statistics.state.guess3 = $guess
          statistics.state.attempts3 = statistics.state.attempts3 + 1
          statistics.state.guess3Result = 'correct'
        }
        // statistics.state.attempts = 3

        
        

        // set streak and date
        if (statistics.state.lastPlayedDate === yesterdaysDate) {
          statistics.state.streak = statistics.state.streak + 1
        } else {
          statistics.state.streak = 1
        }
        statistics.state.lastPlayedDate = todaysDate

        updateRecord(statistics.state.attempts, true, todaysDate)
        updateStats()
        // confetti()
      } else {
        if (statistics.state.attempts === 0) {
            statistics.state.attempts = 1
            statistics.state.showHint2 = true
            statistics.state.guess1 = $guess
            statistics.state.guess1Result = "incorrect"
        } else if (statistics.state.attempts === 1) {
            statistics.state.attempts = 2
            statistics.state.showHint2 = true
            statistics.state.guess2 = $guess
            statistics.state.guess2Result = "incorrect"
        } else if (statistics.state.attempts === 2) {
            statistics.state.attempts = 3
            statistics.state.guess3 = $guess
            statistics.state.guess3Result = "incorrect"
        }

        
        
        displayGuess = $guess
        statistics.state.displayGuess = $guess
        $guess = ''
        // showResult = true
        statistics.state.showResult = true
        placeholder = 'Try Again'
        solved = false
        statistics.state.lastPlayedDate = todaysDate

        updateRecord(statistics.state.attempts, false, todaysDate)

      }
    }
    if (statistics.state.attempts === 3 && solved !== true) {
      showWrong = true
      logUnsolvedResult()
      statistics.state.played = statistics.state.played + 1
      statistics.state.streak = 0
      statistics.state.currentGameCompleted = true
    }


    updateStats()
  }

  // update stats every few seconds
  // setInterval(() => { 
  //   if(browser) {
  //     statistics = JSON.parse(localStorage.getItem(lsname))

  //      // Update played
  //      statistics.state.played = statistics.record.length
  //     // Update solved
  //     statistics.state.solved = statistics.record.filter(s => s.solved).length
      
      


  //     // Update Streak
  //     let streakCount = 0
  //     let streakDone = false
  //     statistics.record.forEach(s => {
  
        
  //       if(s.solved && !streakDone) {
  //         streakCount++
  //         statistics.state.streak = streakCount
  //       } else { 
  //         statistics.state.streak = streakCount
  //         streakDone = true
  //       }
     
  //     })

  //     localStorage.setItem(lsname, JSON.stringify(statistics))
      
  //   }
  // }, 1000)

    // /////////// check for completed game and show answer


    
// if(statistics.state.currentGameDate === todaysDate && statistics.state.currentGameCompleted === true) { 
//   showAnswer = false
// }
 // /////////// check for completed game and show answer
    
</script>



{#if endOfYearShow}
<div class="end-of-year" transition:fly={{ delay: 50, duration: 700, x: 0, y: 500, opacity: 0 }}>
  <div class="end-of-year-inner">
    <div class="title">🎉 &nbsp; 2023 Special &nbsp; 🎉</div>
    The best and worst of Cluedle in 2023. How many of these can you solve? 
    <div class="end-of-year-links">
      <a href="/2023" target="_blank">Play</a> &nbsp; <span class="link" on:click={() => endOfYearShow = false}>Close</span>
    </div>
  </div>
</div>
{/if}

<!-- Show end of year mini -->
<!-- <div class="eoy-mini">🎉 Play 2023 Special</div> -->

{#if dev}
<div class="debugger">
    Today: {today}<br/>
    Yesterday: {yesterday}<br/>
    Attempts: { statistics.state.attempts } <br/>
    ShowHint1: {statistics.state.showHint1}<br/>
    ShowHint2: {statistics.state.showHint2}<br/>
    ShowResult: {statistics.state.showResult}<br/>
    DisplayGuess: {statistics.state.displayGuess}<br/>
    CurrentGameDate: {statistics.state.currentGameDate}<br/>
    currentGameCompleted: {statistics.state.currentGameCompleted}<br/>
    Played: {statistics.state.played}<br/>
    Solved: {statistics.state.solved}<br/>
    Streak: {statistics.state.streak}<br/>
    Attempts1: {statistics.state.attempts1}<br/>
    Attempts2: {statistics.state.attempts2}<br/>
    Attempts3: {statistics.state.attempts3}<br/>
    LastPlayedDate: {statistics.state.lastPlayedDate}<br/>
    Guess1: {statistics.state.guess1}<br/>
    Guess2: {statistics.state.guess2}<br/>
    Guess3: {statistics.state.guess3}<br/>
    TodaysAnswer: {todaysSolution}<br/>
      
  </div>
  {/if}

  <div class="previous-solution">
    <div
      class="previous-solution-title"
      on:click={() => {
        showSolution = !showSolution
      }}
      on:keypress={() => {
        showSolution = !showSolution
      }}
    >
      Yesterday's solution:<MousePointerClick />
    </div>
    {#if showSolution}
      <div
        class="previous-solution-text"
        in:fly={{ y: -100, duration: 800 }}
        out:fade
      >
        <div class="previous-inner-wrap">
          <div class="close-previous">
            <span  on:click={() => {
                showSolution = !showSolution
              }}>
                <X 
                  alt="Close popup"
                 
                />
            </span>
          </div>
          <span class="previous-clue">{yesterdayClue}</span><br />
          <span class="previous-answer">{yesterdaySolution}</span><br />
  
          <span class="previous-clue">{yesterdayExplanation}</span>
        </div>
      </div>
    {/if}
  </div>
  

  
  {#if showWrong}
    <div class="wrong-alert">
      <div class="wrong-alert-inner">
        <div class="wrong-alert-title">Sorry, you didn't get it right today.</div><br /><br />
        The correct answer was <strong>"{todaysSolution}"</strong><br />
        <div class="wrong-note">The solution for this clue will be published tomorrow</div>
        <br />
        
        <button class="wrong-button" on:click={() => (showWrong = false)}
          >Okay</button
        >
        <div class="sign-up-small">🔔 <a href="/subscribe">Sign up to get the daily clue</a></div>
      </div>
    </div>
  {/if}
  <!-- {/if} -->
  

  <main>
    <!-- <div class="todays-clue">Today's Clue</div> -->
    <!-- {#if dayCount === 0}<img src="{Sizzler}" class="sizzler" alt="Sizzler" />{/if} -->
    <div class="clue">{todaysClue}</div>
    {#if todaysSetter !== ''}
    <div class="setter">Clue setter: <span class="setter-name">{todaysSetter}</span></div>
    {/if}

    <div class="answer-box answer-show">
      <!-- <input type="text" placeholder="Your Answer" bind:value="{$guess}" disabled={statistics.state.currentGameCompleted === true}/> -->
      <!-- {todaysSolution} -->

      {#if showAnswer}
     <div class="answer-blocks">
        {#each todaysSolution as letter,i} 
        <div class="answer-block">{@html $currentSolution[i] ? $currentSolution[i].toUpperCase() : '&nbsp;'}</div>
        <!-- Add spacer if required -->
        {#if i === todaysLetterCounter[0].length - 1}<br/>{/if}
        {/each}
    </div>
    {:else}
    <div class="answer-blocks">
      {#each todaysSolution as letter,i} 
      <div class="answer-block">{@html $guess[i] ? $guess[i].toUpperCase() : '&nbsp;'}</div>
      <!-- Add spacer if required -->
      {#if i === todaysLetterCounter[0].length - 1}<br/>{/if}
      {/each}
  </div>
  {/if}

    </div>

   

    <!-- <div class="sharing">
      Share <Share2 strokeWidth="2px" size="16"/>
    </div> -->

    <div class="previous">
        {#if statistics.state.attempts > 0 && statistics.state.guess1 !== null}
            <div class="previous-answer {statistics.state.guess1Result === 'correct' ? 'correct-answer' : 'incorrect-answer'}">
                {#if statistics.state.guess1Result === "incorrect" && statistics.state.guess1 !== null}
                    <XCircle />
                {:else}
                    <CheckCircle2 />
                {/if}
                {statistics.state.guess1.toUpperCase()} 
                {#if statistics.state.attempts === 1} is {statistics.state.guess1Result} {/if}</div>
        {/if}
    
        {#if statistics.state.attempts > 1 && statistics.state.guess2 !== null}
        <div class="previous-answer {statistics.state.guess2Result === 'correct' ? 'correct-answer' : 'incorrect-answer'}">
            {#if statistics.state.guess2Result === "incorrect" && statistics.state.guess2 !== null}
                    <XCircle />
                {:else}
                    <CheckCircle2 />
                {/if}
                {statistics.state.guess2.toUpperCase()}</div>
        {/if}
    
        {#if statistics.state.attempts > 2 && statistics.state.guess3 !== null}
        <div class="previous-answer {statistics.state.guess3Result === 'correct' ? 'correct-answer' : 'incorrect-answer'}">
            {#if statistics.state.guess3Result === "incorrect" && statistics.state.guess3 !== null}
                    <XCircle />
                {:else}
                    <CheckCircle2 />
                {/if} {statistics.state.guess3.toUpperCase()}</div>
      
        {/if}
        </div>
   
  
    
  
   
  
   
    
    
    <Keyboard {guess} showHint1 = { statistics.state.showHint1 } showHint2 = { statistics.state.showHint2 } todayHint1 = { todayHint1 } todayHint2 = { todayHint2 } on:check={checkAnswer} />
  </main>
  <style>

    .end-of-year { 
      position: fixed; 
      top: 20vh;
      width: 100%; 
      text-align: center;
      z-index: 1000;
   
      
      

    }
    .end-of-year-inner { 
      background: #FF0300;
      color: #fff;
      margin-top: 10vh;
      font-size: 0.8rem;
      border-radius: 3px;
      width: 70%;
      max-width: 350px; 
      padding: 20px 40px;
      line-height: 1.4;
      margin-left: auto;
      margin-right: auto;
      -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.18);
-moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.18);
box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.18);
border: solid 3px #fff;
      /* border: solid 3px red; */
      
    }
    .end-of-year .title { 
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom:4px;
      font-size: 1rem;

    }
    .end-of-year-links { 
      margin-top: 20px;
      font-weight: 700;
      
    }
    .end-of-year-links a { 
      color: #fff;
      text-decoration: none;
      border: solid 1px #fff;
      padding: 10px 15px;
      cursor: pointer;

    }
    .end-of-year-links .link { 
      color: #fff;
      text-decoration: none;
      border: solid 1px #fff;
      padding: 10px 15px;
      cursor: pointer;
      background: #FF0300;
     
    }
    .end-of-year-links a:hover { 
      filter: brightness(1.2);
    }
    .answer-block { 
      margin-right: 0px;
      /* color: red; */
      padding: 7px;
    }
    @media only screen and (max-width: 800px) {
      .answer-block { 
        padding: 3px;
      }
    }

    /* .sharing { 
      margin-top:10px;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: gray;
      cursor: pointer;
    }
    .sharing:hover { 
      color: var(--primary-color);
    }
    :global(.sharing svg) { 
      transform: translate(0px,3px);
    } */
    .sizzler { 
      width: 100%;
      max-width: 150px;
    }
   .sign-up-small { 
    margin-top: 20px;
    font-size: 0.9rem;
   }
   .sign-up-small a { 
    color: #000; 
    text-decoration: underline; 
    cursor: pointer;
   }
   .sign-up-small a:hover { 
    color: var(--primary-color);
   }
   .wrong-note { 
    margin-top: 20px;
    font-size: 0.9rem;
    color: gray;
   }
  </style>